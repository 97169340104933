module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"IxDTW 互動設計年會 2021","short_name":"IxDTW2021","start_url":"/","background_color":"#A71FE7","theme_color":"#A71FE7","display":"minimal-ui","icon":"src/images/ixdtw2021-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1ca4aeda9b41725b6ec9c788ce3bb97f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"style":{"overlay":{"backgroundColor":"rgba(0,0,0,0.7)","zIndex":1000,"overflow":"auto"},"content":{"position":"unset","inset":"unset","padding":"0 0 10vh 0","border":0,"background":"transparent","outline":"none","maxWidth":700,"overflow":"unset","margin":"10vh auto 0 auto"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-177230648-1","head":true,"anonymize":true,"defer":false,"cookieDomain":"ixda.org.tw","respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
