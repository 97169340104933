// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-agenda-yaml-id-js": () => import("./../../../src/pages/agenda/{AgendaYaml.id}.js" /* webpackChunkName: "component---src-pages-agenda-agenda-yaml-id-js" */),
  "component---src-pages-agenda-qa-js": () => import("./../../../src/pages/agenda/qa.js" /* webpackChunkName: "component---src-pages-agenda-qa-js" */),
  "component---src-pages-agenda-qa-strapi-session-strapi-id-js": () => import("./../../../src/pages/agenda/qa/{StrapiSession.strapiId}.js" /* webpackChunkName: "component---src-pages-agenda-qa-strapi-session-strapi-id-js" */),
  "component---src-pages-agenda-section-strapi-session-strapi-id-js": () => import("./../../../src/pages/agenda/section/{StrapiSession.strapiId}.js" /* webpackChunkName: "component---src-pages-agenda-section-strapi-session-strapi-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-press-strapi-press-slug-js": () => import("./../../../src/pages/press/{StrapiPress.slug}.js" /* webpackChunkName: "component---src-pages-press-strapi-press-slug-js" */),
  "component---src-pages-speaker-js": () => import("./../../../src/pages/speaker.js" /* webpackChunkName: "component---src-pages-speaker-js" */),
  "component---src-pages-speaker-strapi-speaker-slug-js": () => import("./../../../src/pages/speaker/{StrapiSpeaker.slug}.js" /* webpackChunkName: "component---src-pages-speaker-strapi-speaker-slug-js" */),
  "component---src-pages-strapi-page-slug-js": () => import("./../../../src/pages/{StrapiPage.slug}.js" /* webpackChunkName: "component---src-pages-strapi-page-slug-js" */)
}

